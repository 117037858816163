const initialState = {
  stations: {
    data: [],
    total: 0
  },
  videos: {
    data:[],
    total:0
  },
}

const stationReducer = (state = initialState, action) => {

  switch (action.type) {
    case 'videoStorage/GET_STATIONS':
      return { ...state, stations: action.stations }
    case 'videoStorage/GET_VIDEO_STATION':
      return { ...state, videos: action.videos }
    default:
      return state
  }
}

export default stationReducer
