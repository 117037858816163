// ** Initial State
const initialState = {
  data: [],
}

const stationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_STATIONS':
      return { ...state, data: action.payload.data || [] }
    default:
      return state
  }
}

export default stationReducer
